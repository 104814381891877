@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #0A0A0A;
  color: white;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans', sans-serif;
}
